import { getUrlWithHostAndProtocol, isLocalhost } from '@app/utils';
//@ts-expect-error Types don't work for NR
import { BrowserAgent } from '@newrelic/browser-agent';
import { JSErrors } from '@newrelic/browser-agent/features/jserrors';
import { ToastProvider } from '@shared/react_components/Toast';
import { getConfig } from '@shared/utils/config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'bootstrap-icons/font/bootstrap-icons.scss';
// Polyfill according to .browserslist
import 'core-js/stable';
import posthog from 'posthog-js';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import invariant from 'tiny-invariant';
import { Userpilot } from 'userpilot';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from './App';

import './styles/styles.scss';
import './styles/main.scss';

const NUM_RETRIES = 3;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: Boolean(window.Cypress) ? false : NUM_RETRIES,
    },
  },
});

const config = getConfig();
if (config.vendor.new_relic) {
  new BrowserAgent({
    init: config.vendor.new_relic.init,
    info: config.vendor.new_relic.info,
    loader_config: config.vendor.new_relic.loader_config,
    features: [JSErrors],
  });
}

if (!config.production && !window.Cypress) {
  import('../msw/browser').then(({ worker }) => {
    worker.start();
  });
}

const currentBaseUrl = getUrlWithHostAndProtocol();
const isDev = isLocalhost(currentBaseUrl);

/**
 * @remarks MutationObserver is a platform API
 * that lets us listen to DOM structure changes. We can leverage this
 * to detect when Intercom's message modal container is mounted to the DOM
 * so we can add Posthog's masking css classname to mask it.
 */
const observer = new MutationObserver((mutationList) => {
  for (let mutation of mutationList) {
    // AddedNodes is a list of Node types so we need to type narrow to a DOM element type
    if (mutation.addedNodes[0] instanceof Element && mutation.addedNodes[0].id) {
      const id = mutation.addedNodes[0].id;
      if (id === 'intercom-container') {
        mutation.addedNodes[0].classList.add('ph-no-capture');
      }
    }
  }
});

observer.observe(document.body, { childList: true });

// Initialize UP
Userpilot.initialize('NX-bf770575');

// Initialize Posthog
posthog.init(config.vendor.posthogKey, {
  // Use Vercel's rewrites to reverse proxy posthog traffic and avoid potential ad blockers.
  api_host: isDev ? 'https://app.posthog.com' : `${currentBaseUrl}/ingest`,
  // Opt to manually instrument to avoid leaking PII.
  autocapture: false,
  // Only enable session recording in prod.
  disable_session_recording: !config.production,
  /**
   * Session recording config.
   *  - All inputs are masked by default
   */
  enable_recording_console_log: false, // Disallow another avenue of leaking PII until we have safe guardrails
  session_recording: {
    maskTextSelector: '.sensitive', // Mask all text elements that contain this classname
    maskInputFn: (text, element) => {
      if (element?.attributes?.getNamedItem('data-nonsensitive')?.value === 'true') {
        // If this element has this data attribute, don't mask it.
        return text;
      }
      // Otherwise, mask it with asterisks
      return '*'.repeat(text.length);
    },
  },
});

const container = document.getElementById('app');
invariant(container);
const root = createRoot(container);
root.render(
  <StrictMode>
    <ToastProvider />
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </StrictMode>
);
