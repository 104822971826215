import LightningBoltIcon from '@assets/svgs/lightning_bolt_gradient.svg';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shared/react_components/Tooltip';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { mergeClasses } from '@shared/react_components/css_utils';
import { EVENTS } from '@shared/constants/events';
import posthog from 'posthog-js';

export const primaryGradientBackgroundClass = `tw-bg-gradient-to-br tw-from-[#8D69EE] tw-to-[#D235CC] tw-bg-background-shift tw-animate-background-shift`;
const sizes = {
  sm: {
    containerClassName: 'tw-h-8 tw-w-full tw-p-[2px] tw-rounded-lg',
    innerContainerClass: 'tw-bg-white tw-rounded-[6px] tw-px-2 tw-py-1',
  },
  md: {
    containerClassName: 'tw-h-12 tw-w-full tw-p-[3px] tw-rounded-lg',
    innerContainerClass: 'tw-bg-white tw-rounded-[5px] tw-px-2 tw-py-1',
  },
};
const innerContainerClass =
  'tw-h-full tw-w-full tw-shadow-none tw-relative tw-flex tw-items-center tw-justify-start tw-flex-row tw-will-change-auto tw-bg-white tw-rounded-lg tw-px-2 tw-py-1';

type UpgradeToProButtonProps = {
  size?: 'sm' | 'md';
};

export function useNavigateToSubscriptionPage() {
  const params = useParams<{ ecp_id: string; lab_id: string; order_id: string }>();
  const navigate = useNavigate();
  const path = `/ecp/${params.ecp_id}/lab/${params.lab_id}/settings/subscription-plans`;
  const navigateToSubscriptionPage = useCallback(() => {
    posthog.capture(EVENTS.UPGRADE_TO_PRO_BUTTON_CLICKED);
    navigate(path);
  }, [navigate, path]);

  return {
    navigateToSubscriptionPage,
  };
}

export function UpgradeToProButton({ size }: UpgradeToProButtonProps) {
  const { navigateToSubscriptionPage } = useNavigateToSubscriptionPage();

  return (
    <button
      type="button"
      className={mergeClasses(
        'tw-border-none', // Button styles
        sizes[size ?? 'md'].containerClassName, // Size
        primaryGradientBackgroundClass // Gradient background
      )}
      onClick={navigateToSubscriptionPage}
    >
      <span className={mergeClasses(innerContainerClass, sizes[size ?? 'md'].innerContainerClass)}>
        <span className="tw-h-6 tw-w-6 tw-flex tw-items-center tw-justify-center">
          <LightningBoltIcon />
        </span>
        <span className="tw-text-primary tw-text-base">Upgrade to Pro</span>
      </span>
    </button>
  );
}

type Props = {
  displayMode: 'expanded' | 'collapsed';
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile?: boolean;
};
function UpgradeToProNavButton({ displayMode, setSidebarOpen }: Props) {
  const { navigateToSubscriptionPage } = useNavigateToSubscriptionPage();

  return (
    <Tooltip disableHoverableContent delayDuration={0}>
      <TooltipTrigger asChild>
        <button
          className={mergeClasses(
            'tw-border-none', // Button styles
            sizes.md.containerClassName, // Size
            primaryGradientBackgroundClass // Gradient background
          )}
          onClick={() => {
            setSidebarOpen(false);
            navigateToSubscriptionPage();
          }}
        >
          <span className={mergeClasses(innerContainerClass, sizes.md.innerContainerClass)}>
            <span className="tw-h-6 tw-w-6 tw-flex tw-items-center tw-justify-center">
              <LightningBoltIcon />
            </span>
            <AnimatePresence>
              {displayMode === 'expanded' && (
                <motion.div
                  variants={{
                    collapsed: { opacity: 0 },
                    expanded: { opacity: 1 },
                  }}
                  initial="collapsed"
                  animate="expanded"
                  exit="collapsed"
                  transition={{ duration: 0.2 }}
                  className="tw-absolute tw-left-12 tw-flex tw-items-center tw-justify-start tw-flex-row tw-gap-2 tw-w-32"
                >
                  <span className="tw-text-primary tw-text-base">Upgrade to Pro</span>
                </motion.div>
              )}
            </AnimatePresence>
          </span>
        </button>
      </TooltipTrigger>
      {displayMode === 'collapsed' && (
        <TooltipContent side="right" sideOffset={25} withArrow={false}>
          Upgrade to Pro
        </TooltipContent>
      )}
    </Tooltip>
  );
}

export default UpgradeToProNavButton;
