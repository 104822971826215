import React, { PropsWithChildren } from 'react';
import { cn } from '@shared/utils/styles';

import LabIcon from '@assets/icons/Lab-24-CurrentColor.svg';
import BuildingIcon from '@assets/icons/Building-24-CurrentColor.svg';
import { ResourceType } from './types';

interface CommonProps {
  className?: string;
}

export function ResourceName({ className, children }: PropsWithChildren<CommonProps>) {
  return <span className={cn('tw-text-sm tw-leading-none tw-font-semibold', className)}>{children}</span>;
}

export function ResourceMeta({ className, children }: PropsWithChildren<CommonProps>) {
  return <span className={cn('tw-text-[11px] tw-leading-tight tw-font-normal', className)}>{children}</span>;
}

export function ResourceIcon({
  resourceType,
  className,
}: CommonProps & {
  resourceType: ResourceType;
}) {
  return (
    <div
      className={cn(
        'tw-flex-shrink-0 tw-w-7 tw-h-7 md:tw-w-8 md:tw-h-8 tw-aspect-square tw-flex tw-justify-center tw-items-center tw-flex-row tw-rounded-lg',
        className
      )}
    >
      {resourceType === 'lab' ? <LabIcon /> : <BuildingIcon />}
    </div>
  );
}
