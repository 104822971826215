import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { mergeClasses } from './css_utils';

const buttonVariants = cva(
  'tw-inline-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded-md tw-text-md tw-font-normal tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring disabled:tw-pointer-events-none disabled:tw-opacity-50 tw-border-none',
  {
    variants: {
      variant: {
        default: 'tw-bg-primary-500 tw-text-primary-foreground tw-shadow hover:tw-bg-primary-600 disabled:tw-bg-primary-200',
        destructive:
          'tw-bg-destructive tw-text-destructive-foreground tw-shadow-sm hover:tw-bg-destructive/90 disabled:tw-bg-destructive/50',
        outline:
          'tw-border-solid tw-border tw-border-neutral-300 tw-text-neutral-900 tw-bg-background tw-shadow-sm hover:tw-bg-neutral-100 disabled:tw-border-neutral-200',
        outlineDisabled: 'tw-border-solid tw-border tw-border-neutral-200 tw-text-neutral-200 tw-shadow-sm',
        secondary: 'tw-bg-secondary tw-text-secondary-foreground tw-shadow-sm hover:tw-bg-secondary/80 disabled:tw-bg-secondary/50',
        ghost: 'tw-border-none tw-text-primary-500 hover:tw-bg-primary-50 disabled:tw-text-primary-200',
        ghostPurple: 'tw-border-none tw-text-purple-500 hover:tw-bg-purple-100 disabled:tw-text-purple-300',
        destructiveGhost: 'tw-border-none tw-text-red-500 hover:tw-bg-red-50 disabled:tw-text-red-300',
        informationGhost: 'tw-border-none tw-text-information-600 hover:tw-bg-information-100 disabled:tw-text-information-300',
        link: 'tw-text-neutral-600 underline-offset-4 hover:underline disabled:tw-no-underline',
        primaryLink: 'tw-text-primary-500 hover:tw-bg-primary-50 disabled:tw-text-primary-300',
        tertiary:
          '!tw-border !tw-border-solid !tw-border-neutral-200 tw-bg-white tw-text-secondary-foreground tw-shadow-sm hover:tw-bg-neutral-100 disabled:tw-border-neutral-300 disabled:tw-bg-neutral-50',
      },

      size: {
        default: 'tw-h-9 tw-px-4 tw-py-2',
        xs: 'tw-h-6 tw-rounded-full tw-px-3 tw-py-1 tw-text-sm',
        sm: 'tw-h-8 tw-rounded-md tw-px-3 tw-text-sm',
        lg: 'tw-h-10 tw-rounded-md tw-px-8',
        xl: 'tw-h-12 tw-rounded-lg tw-px-4 tw-py-3.5',
        icon: 'tw-h-9 tw-w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  'data-testid'?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, type, 'data-testid': dataTestId, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={mergeClasses(buttonVariants({ variant, size, className }))}
        ref={ref}
        type={type ?? 'button'}
        data-testid={dataTestId}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
